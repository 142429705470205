import { createRouter, createWebHashHistory } from 'vue-router'

function isAuthenticated() {
  const user = JSON.parse(localStorage.getItem('user'));
  return user;
}

const routes = [
  // ROUTES FOR ADMIN
  {
    path: '/',
    name: 'default_view',
    component: () => import('../views/default.vue'),
    meta: { requiresAuth: false } 
  },
  {
    path: '/minha-conta',
    name: 'my_account',
    component: () => import('../views/my-account.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/iniciar-sessao',
    name: 'login',
    component: () => import('../views/login.vue'),
    meta: { requiresAuth: false } 
  },
  {
    path: '/registar',
    name: 'register',
    component: () => import('../views/register.vue'),
    meta: { requiresAuth: false } 
  },
  {
    path: '/carrinho',
    name: 'carrnho',
    component: () => import('../views/cart.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/categorias',
    name: 'categorias',
    component: () => import('../views/categoria.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/search',
    name: 'search',
    component: () => import('../views/search-product.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/finalizar-pagamento',
    name: 'checkout',
    component: () => import('../views/checkout.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/detalhes-produto/:id/',
    name: 'detalhes-produto',
    component: () => import('../views/single-product.vue'),
    meta: { requiresAuth: false } 
  },
  {
    path: '/detalhes-promocao/:id/',
    name: 'detalhes-promocao',
    component: () => import('../views/single-promo.vue'),
    meta: { requiresAuth: false } 
  },
  {
    path: '/produtos-categoria/:id/',
    name: 'produtos_category',
    component: () => import('../views/list-by-category.vue'),
    meta: { requiresAuth: false } 
  },
  {
    path: '/produtos-novos/',
    name: 'produtos_news',
    component: () => import('../views/list-news.vue'),
    meta: { requiresAuth: false } 
  },
  {
    path: '/promocoes/',
    name: 'promocoes',
    component: () => import('../views/list-promocoes.vue'),
    meta: { requiresAuth: false } 
  },
  {
    path: '/favoritos',
    name: 'whishlist',
    component: () => import('../views/wishlist.vue'),
    meta: { requiresAuth: true } 
  },
  // UTILS
  { 
    path: '/:pathMatch(.*)*',
    name: 'NotFound', 
    component: () => import('../views/404.vue'),
    meta: { requiresAuth: false } 
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  }
});

// Hook global para restaurar a rolagem e verificar autenticação
router.beforeEach((to, from, next) => {
  document.body.style.overflow = 'auto';  
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!isAuthenticated()) {
      next('/iniciar-sessao');
    } else {
      next();
    }
  } else {
    next();
  }
});


export default router