import { createStore } from 'vuex'

const store = createStore({
  state: {
    user: JSON.parse(localStorage.getItem("user")),
    token: localStorage.getItem("token"),
    isAuthenticated: localStorage.getItem("logged") === "true" ? true : false,
    favoritosCount: 0,
    carrinhosCount: 0
  },
  getters: {
    getToken: state => state.token,
    isAuthenticated: state => state.isAuthenticated,
    getUser: state => state.user,
    getFavoritosCount: state => state.favoritosCount,
    getCarrinhosCount: state => state.carrinhosCount
  },
  mutations: {
    login(state, { user, token }) {
      state.isAuthenticated = true;
      state.user = JSON.parse(localStorage.getItem("user"));
      state.authUser    = user;
      state.token = token;
      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("token", token);
      localStorage.setItem("logged", "true");
    },
    logout(state) {
      state.isAuthenticated = false;
      state.user = null;
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      localStorage.setItem("logged", "false");
    },
    updateUser(state, updatedUser ) {
      state.user = updatedUser   ;
      localStorage.setItem("user", JSON.stringify(updatedUser ));
    },
    updateAuthenticated(state, isAuthenticated) {
      state.isAuthenticated = isAuthenticated;
    },
    updateFavoritosCount(state, count) {
      state.favoritosCount = count;
    },
    updateCarrinhoCount(state, count) {
      state.carrinhosCount = count;
    }
  },
  actions: {
  },
  modules: {
  }
})

export default store;