import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import './views/assets/css/all.min.css';
import 'sweetalert2/dist/sweetalert2.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import 'datatables.net-bs5';
import 'datatables.net-buttons-bs5';
import 'datatables.net-responsive-bs5';
import VueSweetalert2 from 'vue-sweetalert2';
import store from './store';
import VueTheMask from 'vue-the-mask';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import $ from 'jquery';
window.$ = $;
import { createMetaManager } from 'vue-meta';
const metaManager = createMetaManager();
const app = createApp(App);

app.use(router);
app.use(metaManager);
app.use(VueSweetalert2);
app.use(store);
app.use(VueTheMask);
app.component('v-select', vSelect);
app.mount('#app');